#footer {
  width: 100%;
  height: 5vh;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

#footer p {
  color: white;
}
