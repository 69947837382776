#portfolio {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10vh;
  /* background-color: red; */
}

#portfolio h2 {
  text-transform: uppercase;
  font-size: 2rem;
  border-top: 3px solid rgb(18, 54, 82);
  padding: 10vh 5% 5vh;
  color: rgb(52, 158, 118);
}

.projects {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  width: 85%;
  /* height: 100%; */
}

.filterDiv {
  float: left;
  color: #ffffff;
  width: 100%;
  height: 23vh;
  text-align: center;
  margin: 10px 20px;
  /* display: none; */
  padding: 0;
  border-radius: 30px;
}

.filterDiv img {
  width: 100%;
  height: 100%;
}

.show {
  display: block;
}

.btn {
  border: none;
  outline: none;
  padding: 12px 16px;
  background-color: #f1f1f1;
  cursor: pointer;
  margin: 0 10px;
}

.btn:hover {
  background-color: #ddd;
}

.btn.active {
  background-color: #666;
  color: white;
}

.contenedor {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  flex-wrap: wrap;
}

.contenedor figure {
  position: relative;
  overflow: hidden;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  border-radius: 10px;
}

.contenedor figure img {
  width: 100%;
  height: 100%;
  transition: all 500ms ease-in-out;
}

.contenedor figure .capa {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(18, 54, 82, 0.7);
  transition: all 500ms ease-in-out;
  opacity: 0;
  visibility: hidden;
  text-align: center;
}

.contenedor figure:hover > .capa {
  opacity: 1;
  visibility: visible;
}

.contenedor figure:hover > .capa h3 {
  margin-top: 50px;
  margin-bottom: 15px;
}

.contenedor figure:hover > img {
  transform: scale(1.3);
}

.contenedor figure .capa h3 {
  color: white;
  margin-bottom: 120px;
  transition: all 500ms ease-in-out;
  margin-top: 30px;
  text-transform: uppercase;
}

.contenedor figure .capa p {
  color: white;
  font-size: 15px;
  line-height: 1.5;
  width: 100%;
  max-width: 220px;
  margin: auto;
}

.capa i {
  color: white;
  font-size: 1.5rem;
  margin: 3vh 20px;
  transition: all 0.2s ease-in-out;
}

.capa i:hover {
  color: yellow;
  transition: all 0.2s ease-in-out;
}

@media (min-width: 700px) {
  .projects {
    flex-direction: row;
    width: 95%;
    /* height: 100%; */
  }
  .filterDiv {
    width: 20vw;
  }

  .contenedor {
    width: 100%;
    flex-direction: row;
  }
}
