header {
  width: 100%;
  height: 8vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0px;
  background-color: rgb(18, 54, 82);
  z-index: 1;
}

header img {
  height: 3em;
  margin-top: 5px;
  margin-left: 8%;
  z-index: 3;
}

#site-nav {
  margin-left: 40%;
}

.menu {
  width: 50%;
  margin: 0;
  right: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu,
.menu ul {
  display: flex;
}

.menu li a {
  padding: 1.5vh 5px;
  font-size: 1.1em;
  color: white;
  background-image: linear-gradient(rgb(255, 212, 50), rgb(255, 212, 50));
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size 0.4s;
}

li a:hover,
a:focus {
  background-size: 100% 2px;
  color: rgb(255, 212, 50);
}

.menu ul li a {
  margin: 0 20px;
}

@media (max-width: 930px) {
  header {
    z-index: 2;
  }

  .progreso {
    z-index: 1;
  }

  .barra {
    z-index: 1;
  }

  /* ========= INICIO MENU HAMBURUGUESA ======== */
  .menu {
    top: 0;
    right: 0;
    margin-top: 4vh;
    width: 100%;
    height: 100vh;
    position: absolute;
    background-color: rgb(18, 54, 82);
    clip-path: circle(0px at top right);
    transition: clip-path ease-in-out 700ms;
    display: flex;
    justify-content: space-between;
    border-radius: 15px;
  }

  .site-nav-open {
    clip-path: circle(150% at top right);
  }

  .menu ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .menu li {
    font-size: 1.5rem;
    margin: 7vh auto;
  }

  .menu-toggle {
    padding: 1rem;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 2vh;
  }

  .menu-open .hamburger {
    transform: rotate(45deg);
  }

  .menu-open .hamburger::before {
    opacity: 0;
  }

  .menu-open .hamburger::after {
    transform: translateY(-3px) rotate(-90deg);
  }

  .hamburger,
  .hamburger::before,
  .hamburger::after {
    content: "";
    display: block;
    background-color: rgb(255, 212, 50);
    height: 0.2rem;
    width: 2em;
    border-radius: 3px;
    transition: all ease-in-out 500ms;
  }

  .hamburger::before {
    transform: translateY(-6px);
  }

  .hamburger::after {
    transform: translateY(3px);
  }
  /* ========= FIN MENU HAMBURUGUESA ======== */
}
