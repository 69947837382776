#skills {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 10vh 0;
}

#skills h2 {
  text-transform: uppercase;
  font-size: 2rem;
  border-top: 3px solid rgb(18, 54, 82);
  padding: 10vh 5% 0% 5%;
  color: rgb(52, 158, 118);
}

#icons-skill {
  margin-top: 5vh;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.icon-skill {
  width: 25vw;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
  flex-direction: column;
  margin: auto 1%;
}

.icon-skill img {
  width: 60%;
  margin-bottom: 1vh;
  transition: 1.3s ease;
}

.icon-skill p {
  font-size: 1rem;
  text-align: center;
}

.icon-skill img:hover {
  scale: 1.5;
  transform: rotate(360deg);
  transform: rotate(360deg);
  transition: 1.3s ease;
}

.cardSkill {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #f0f0f0;
  margin: 1vh auto;
  border-radius: 10px;
  /* border: 1px solid grey; */
}

@media (min-width: 700px) {
  .icon-skill img {
    width: 5vw;
    margin-bottom: 1vh;
    transition: 1.3s ease;
  }
  .icon-skill {
    width: 6vw;
  }
  #icons-skill {
    width: 95vw;
  }
  .cardSkill {
    width: 30%;
    height: 50ch;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}
