#home {
  width: 100%;
  height: 75vh;
  background-color: rgb(5, 34, 48);
  display: flex;
  position: relative;
  /* overflow: hidden; */
}

.info {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.presents {
  width: 90vw;
  position: absolute;
  top: 10vh;
  left: 5vw;
  color: white;
  text-align: center;
}

.presents p {
  font-size: 1.3rem;
  color: white;
}

.presents h1 {
  font-size: 4rem;
  text-transform: uppercase;
  margin: 5vh auto;
}

.maq {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3vh;
}

.presents h2 {
  font-size: 1.4rem;
  color: rgb(255, 212, 50);
  display: inline;
}

.icons {
  margin-top: 5vh;
  width: 100%;
}

.icons i {
  color: rgb(99, 99, 99);
  font-size: 2rem;
  margin: 0 10px;
  transition: 0.3s ease-in-out;
}

.icons i:hover {
  color: rgb(255, 212, 50);
  transition: 0.3s ease-in-out;
}

.img-lucas {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.img-lucas img {
  width: 0px;
  height: 100%;
  margin-top: 2vh;
  border-radius: 50px;
  /* opacity: 0; */
  visibility: hidden;
}

.custom-shape-divider-bottom-1642030938 {
  /* background-color: red; */
  margin: auto;
  padding: auto;
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 8vh;
}

.custom-shape-divider-bottom-1642030938 svg {
  width: calc(100% + 1.3px);
  height: 8vh;
  position: relative;
  bottom: -1px;
}

.custom-shape-divider-bottom-1642030938 .shape-fill {
  fill: #e9e9e9;
}

@media (min-width: 600px) {
  .presents {
    width: 40%;
    text-align: start;
    margin-left: 2vw;
    margin-top: 4vh;
  }
  .img-lucas img {
    /* opacity: 1; */
    width: auto;
    height: auto;
    visibility: visible;
  }

  .presents p {
    font-size: 2rem;
  }

  .presents h1 {
    font-size: 6rem;
    margin: 2vh auto;
  }

  .presents h2 {
    font-size: 2rem;
  }

  .img-lucas img {
    margin-right: 10vw;
  }
  .icons i {
    font-size: 2.5rem;
    margin: 0 2vh;
  }
  .maq {
    justify-content: start;
    margin-left: 0.5vw;
  }
}
@media (max-width: 340px) {
  #home {
    height: 85vh;
    margin-bottom: 15vh;
  }
}
