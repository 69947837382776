#about-me {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.text h2 {
  font-size: 2.2rem;
  font-weight: 800;
  color: rgb(52, 158, 118);
  /* margin-top: -15vh; */
  position: relative;
  top: 0;
  margin: 5vh 0;
}

.text h3 {
  font-size: 2.2rem;
  font-weight: 800;
  color: rgb(52, 158, 118);
  margin: 5vh 0;
}

.text p {
  font-size: 1.1rem;
  color: rgb(5, 34, 48);
  text-align: center;
}

.text {
  width: 90vw;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
}

.btn-cv {
  margin-top: 15vh;
  width: 40%;
  height: 8vh;
  font-size: 1.1rem;
  border: 1px solid rgb(5, 34, 48);
  cursor: pointer;
  transition: 0.3s ease;
  border-radius: 4px;
  text-align: center;
  color: rgb(5, 34, 48);
  padding-top: 3%;
}

.btn-cv:hover {
  background: rgb(5, 34, 48);
  color: rgb(255, 212, 50);
  transition: 0.3s ease;
}

.button {
  position: relative;
  padding: 1em 1.5em;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: 0;
  font-size: 18px;
  margin-top: 5vh;
}

.button.type3 {
  color: rgb(5, 34, 48);
}

.button.type3.type3::after,
.button.type3.type3::before {
  content: "";
  display: block;
  position: absolute;
  width: 20%;
  height: 20%;
  border: 3px solid;
  transition: all 0.6s ease;
  border-radius: 3px;
}
.button.type3.type3::after {
  bottom: 0;
  right: 0;
  border-color: transparent rgb(5, 34, 48) rgb(5, 34, 48) transparent;
}
.button.type3.type3::before {
  top: 0;
  left: 0;
  border-color: rgb(5, 34, 48) transparent transparent rgb(5, 34, 48);
}
.button.type3.type3:hover:after,
.button.type3.type3:hover:before {
  border-color: rgb(52, 158, 118);
  width: 100%;
  height: 100%;
}

@media (min-width: 900px) {
  #about-me {
    margin: 5vh auto;
    flex-direction: row;
    height: 40vh;
    justify-content: space-evenly;
    align-items: start;
  }
  .text {
    width: 40vw;
    align-items: start;
    flex-direction: column;
  }
  .text p {
    text-align: start;
    width: 95%;
    /* margin-left: 5vw; */
    /* background-color: red; */
  }
  .text h2,
  .text h3 {
    /* margin: 2vh auto 2vh 5vw; */
    margin: 2vh 0;
  }
}
